// Screen Hidden Content
.visuallyhidden {
    @extend %screen-hide;
}

// When screen hidden content is focused restore it to view
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    @extend %screen-show;
}

// Bootstrap friendly class
.sr-only {
    @extend .visuallyhidden;
}

// Clear fix class for containers of floated elements
.clearfix {
    @extend %clearfix;
}

// Hide from both screenreaders and browsers: h5bp.com/u
.hidden {
    display: none !important;
    visibility: hidden;
}

// Hide visually and from screenreaders, but maintain layout
.invisible {
    visibility: hidden;
}

// Float classes
.pull-left {
    float: left;
}
img.pull-left {
    margin-right: 1em;
}

.pull-right {
    float: right;
}
img.pull-right {
    margin-left: 1em;
}

// 16:9 Ratio container for embedded video
// This container is suitable for something like youtube videos
.video-container {
    position: relative;
    padding-bottom: 56.25%; // Video area
    padding-top: 30px; // 30px controls container
    height: 0;
    overflow: hidden;
    margin-bottom: 1em;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hide_content {
    display: none;
}

.text-error {
    color: $QLD-color-status__error;
}

.text-success {
    color: $QLD-color-status__success;
}

.text-warning {
    color: $QLD-color-status__caution;
}

.text-info {
    color: $QLD-color-status__info;
}

.text-center {
    text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.qld__text-bold {
  font-weight: 700;
}

.qld__text-normal {
  font-weight: 400;
}

.qld__text-italic {
  font-style: italic;
}

.qld__full-width {
  width: 100%;
}

.qld__full-height {
  height: 100%;
}

.order-first {
    order: -1;
}

.capitalize {
    text-transform: capitalize;
}

.capitalised:first-letter {
    text-transform: uppercase; 
}

.breakout {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

*.qld__max-width,
.qld__max-width,
h1.qld__max-width,
h2.qld__max-width,
h3.qld__max-width,
h4.qld__max-width,
h5.qld__max-width,
h6.qld__max-width,
p.qld__max-width,
ul.qld__max-width,
ol.qld__max-width,
li.qld__max-width,
figure.qld__max-width,
span.qld__max-width,
div.qld__max-width,
a.qld__max-width {
    .qld__grid &,
    .qld__body &,
    .qld__footer &,
    [class^="qld__display"] & {
        max-width: 100%;
    }
}

/** @mixin QLD-fontgrid  replace line hight with this + use the variable (map) */
.qld__body {
    .qld__default-xs,
    .qld__default-sm,
    .qld__default-md,
    .qld__default-lg,
    .qld__default-xl,
    .qld__default-xxl,
    .qld__default-xxxl {
      font-weight: 400;
    }
  
    .qld__no-space {
      line-height: 1 !important;
    }
  
    .qld__default-xs {
      font-size: var(--QLD-default-mobile-xs);
      line-height: 20px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-xs);
      }
    }
  
    .qld__default-sm {
      font-size: var(--QLD-default-mobile-sm);
      line-height: 24px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-sm);
      }
    }
  
    .qld__default-md {
      font-size: var(--QLD-default-mobile-md);
      line-height: 32px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-md);
      }
    }
  
    .qld__default-lg {
      font-size: var(--QLD-default-mobile-lg);
      line-height: 36px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-lg);
      }
    }
  
    .qld__default-xl {
      font-size: var(--QLD-default-mobile-xl);
      line-height: 36px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-xl);
      }
    }
  
    .qld__default-xxl {
      font-size: var(--QLD-default-mobile-xxl);
      line-height: 48px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-xxl);
      }
    }
  
    .qld__default-xxxl {
      font-size: var(--QLD-default-mobile-xxxl);
      line-height: 60px;
  
      &.qld__heading-space {
        line-height: var(--QLD-heading-space-xxxl);
      }
    }
  
    @include QLD-media(lg)  {
      .qld__default-xs {
        font-size: var(--QLD-default-desktop-xs);
        line-height: 20px;
      }
  
      .qld__default-sm {
        font-size: var(--QLD-default-desktop-sm);
        line-height: 24px;
      }
  
      .qld__default-md {
        font-size: var(--QLD-default-desktop-md);
        line-height: 32px;
      }
  
      .qld__default-lg {
        font-size: var(--QLD-default-desktop-lg);
        line-height: 36px;
      }
  
      .qld__default-xl {
        font-size: var(--QLD-default-desktop-xl);
        line-height: 48px;
      }
  
      .qld__default-xxl {
        font-size: var(--QLD-default-desktop-xxl);
        line-height: 60px;
      }
  
      .qld__default-xxxl {
        font-size: var(--QLD-default-desktop-xxxl);
        line-height: 72px;
      }
  
      .qld__default-xs.qld__heading-space {
        line-height: var(--QLD-heading-space-xs);
      }
  
      .qld__default-sm.qld__heading-space {
        line-height: var(--QLD-heading-space-sm);
      }
  
      .qld__default-md.qld__heading-space {
        line-height: var(--QLD-heading-space-md);
      }
  
      .qld__default-lg.qld__heading-space {
        line-height: var(--QLD-heading-space-lg);
      }
  
      .qld__default-xl.qld__heading-space {
        line-height: var(--QLD-heading-space-xl);
      }
  
      .qld__default-xxl.qld__heading-space {
        line-height: var(--QLD-heading-space-xxl);
      }
  
      .qld__default-xxxl.qld__heading-space {
        line-height: var(--QLD-heading-space-xxxl);
      }
    }
}

/**
  Border radius utility
*/

.qld__border-radius--xs {
  border-radius: $QLD-border-radius-xs;
}

.qld__border-radius--sm {
  border-radius: $QLD-border-radius-sm;
}

.qld__border-radius--md {
  border-radius: $QLD-border-radius-md;
}

.qld__border-radius--lg {

  border-radius: $QLD-border-radius-md;

  @include QLD-media(lg) {
    border-radius: $QLD-border-radius-lg;
  }

}

.qld__border-radius--lg {

  border-radius: $QLD-border-radius-lg;

  @include QLD-media(lg) {
    border-radius: $QLD-border-radius-xl;
  }

}

/** 
  Spacing utility classes
*/
.qld__margin-t-section {
  
  margin-top: map-get( $QLD-space-map, $QLD-margin__section--mobile ) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, $QLD-margin__section--desktop ) + px !important;
  }

}

.qld__margin-t-content-block {
  
  margin-top: map-get( $QLD-space-map, $QLD-margin__content-block--mobile ) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, $QLD-margin__content-block--desktop ) + px !important;
  }

}

.qld__margin-t-component {
  
  margin-top: map-get( $QLD-space-map, $QLD-margin__component--mobile) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, $QLD-margin__component--desktop ) + px !important;
  }

}

.qld__margin-t-text-element {
  
  margin-top: map-get( $QLD-space-map, $QLD-margin__text-element--mobile ) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, $QLD-margin__text-element--desktop ) + px !important;
  }

}

.qld__margin-t-p {
  
  margin-top: map-get( $QLD-space-map, $QLD-margin__p--mobile ) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, $QLD-margin__p--desktop ) + px !important;
  }

}

.qld__margin-t-li--sm {
  
  margin: 0;
  padding: 0;

  li, ol, ul {

    margin-top: map-get( $QLD-space-map, $QLD-margin__li-close--mobile ) + px !important;

    @include QLD-media(lg) {
      margin-top: map-get( $QLD-space-map, $QLD-margin__li-close--desktop ) + px !important;
    }

  }

}

.qld__margin-t-li--lg {

  margin: 0;
  padding: 0;
  
  li, ol, ul {

    margin-top: map-get( $QLD-space-map, $QLD-margin__li-wide--mobile ) + px !important;

    @include QLD-media(lg) {
      margin-top: map-get( $QLD-space-map, $QLD-margin__li-wide--desktop ) + px !important;
    }

  }

}

.qld__margin-t-label {
  
  margin-top: map-get( $QLD-space-map, $QLD-margin__lables--mobile ) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, $QLD-margin__lables--desktop ) + px !important;
  }

}


.qld__margin-t-none {
  
  margin-top: map-get( $QLD-space-map, 0 ) + px !important;

  @include QLD-media(lg) {
    margin-top: map-get( $QLD-space-map, 0 ) + px !important;
  }

}

.qld__padding-t-none {
  
  padding-top: map-get( $QLD-space-map, 0 ) + px !important;

}

/** 
  Spacing flex utility row classes
*/
.qld__row-gap-content-block {
  row-gap: map-get( $QLD-space-map, $QLD-margin__content-block--mobile ) + px !important;

  @include QLD-media(lg) {
    row-gap: map-get( $QLD-space-map, $QLD-margin__content-block--desktop ) + px !important;
  }

}

.qld__row-gap-component {
  row-gap: map-get( $QLD-space-map, $QLD-margin__component--mobile ) + px !important;

  @include QLD-media(lg) {
    row-gap: map-get( $QLD-space-map, $QLD-margin__component--desktop ) + px !important;
  }

}

.qld__row-gap-text-element {
  row-gap: map-get( $QLD-space-map, $QLD-margin__text-element--mobile ) + px !important;

  @include QLD-media(lg) {
    row-gap: map-get( $QLD-space-map, $QLD-margin__text-element--desktop ) + px !important;
  }

}

.qld__row-gap-p {
  row-gap: map-get( $QLD-space-map, $QLD-margin__p--mobile ) + px !important;

  @include QLD-media(lg) {
    row-gap: map-get( $QLD-space-map, $QLD-margin__p--desktop ) + px !important;
  }

}

.qld__row-gap-li--sm {
  row-gap: map-get( $QLD-space-map, $QLD-margin__li-close--mobile ) + px !important;

  @include QLD-media(lg) {
    row-gap: map-get( $QLD-space-map, $QLD-margin__li-close--desktop ) + px !important;
  }

}

.qld__row-gap-li--lg {
  row-gap: map-get( $QLD-space-map, $QLD-margin__li-wide--mobile ) + px !important;

  @include QLD-media(lg) {
    row-gap: map-get( $QLD-space-map, $QLD-margin__li-wide--desktop ) + px !important;
  }

}

/** 
  Spacing flex utility column classes
*/

.qld__col-gap-content-block {
  column-gap: map-get( $QLD-space-map, $QLD-margin__content-block--mobile ) + px !important;

  @include QLD-media(lg) {
    column-gap: map-get( $QLD-space-map, $QLD-margin__content-block--desktop ) + px !important;
  }

}

.qld__col-gap-component {
  column-gap: map-get( $QLD-space-map, $QLD-margin__component--mobile ) + px !important;

  @include QLD-media(lg) {
    column-gap: map-get( $QLD-space-map, $QLD-margin__component--desktop ) + px !important;
  }

}

.qld__col-gap-text-element {
  column-gap: map-get( $QLD-space-map, $QLD-margin__text-element--mobile ) + px !important;

  @include QLD-media(lg) {
    column-gap: map-get( $QLD-space-map, $QLD-margin__text-element--desktop ) + px !important;
  }

}

.qld__col-gap-p {
  column-gap: map-get( $QLD-space-map, $QLD-margin__p--mobile ) + px !important;

  @include QLD-media(lg) {
    column-gap: map-get( $QLD-space-map, $QLD-margin__p--desktop ) + px !important;
  }

}

.qld__col-gap-li--sm {
  column-gap: map-get( $QLD-space-map, $QLD-margin__li-close--mobile ) + px !important;

  @include QLD-media(lg) {
    column-gap: map-get( $QLD-space-map, $QLD-margin__li-close--desktop ) + px !important;
  }

}

.qld__col-gap-li--lg {
  column-gap: map-get( $QLD-space-map, $QLD-margin__li-wide--mobile ) + px !important;

  @include QLD-media(lg) {
    column-gap: map-get( $QLD-space-map, $QLD-margin__li-wide--desktop ) + px !important;
  }

}

/*   New Classes that apply the form sizes variables
  ----------------------------------------------------------------------------------------------------------
      
New classes to dictate field size, this applied variables that affec thte max-width of a for inpout field

---------------------------------------------------------------------------------------------------------- */

input.qld__text-input--block.qld__field-width--2char,
select.qld__text-input--block.qld__field-width--2char {
    max-width: $QLD-fixed-width__char-2;
}

input.qld__text-input--block.qld__field-width--3char,
select.qld__text-input--block.qld__field-width--3char {
    max-width: $QLD-fixed-width__char-3;
}

input.qld__text-input--block.qld__field-width--4char,
select.qld__text-input--block.qld__field-width--4char {
    max-width: $QLD-fixed-width__char-4;
}

input.qld__text-input--block.qld__field-width--5char,
select.qld__text-input--block.qld__field-width--5char {
    max-width: $QLD-fixed-width__char-5;
}

input.qld__text-input--block.qld__field-width--10char,
select.qld__text-input--block.qld__field-width--10char {
    max-width: $QLD-fixed-width__char-10;
}

input.qld__text-input--block.qld__field-width--20char,
select.qld__text-input--block.qld__field-width--20char {
    max-width: $QLD-fixed-width__char-20;
}

input.qld__text-input--block.qld__field-width--xs,
select.qld__text-input--block.qld__field-width--xs {
    max-width: $QLD-form-input__width-xs;
}

input.qld__text-input--block.qld__field-width--sm,
select.qld__text-input--block.qld__field-width--sm {
    max-width: $QLD-form-input__width-sm;
}

input.qld__text-input--block.qld__field-width--md,
select.qld__text-input--block.qld__field-width--md {
    max-width: $QLD-form-input__width-md;
}

input.qld__text-input--block.qld__field-width--lg,
select.qld__text-input--block.qld__field-width--lg
{
max-width: $QLD-form-input__width-lg;
}

input.qld__text-input--block.qld__field-width--xl,
select.qld__text-input--block.qld__field-width--xl
{
max-width: $QLD-form-input__width-xl;
}

/*  Default for all fluid styls is full - these are adjusts at larger break points, they keeps the fluid width style fomrs looking good on mobile  */

input.qld__text-input--block.qld__field-width--full,
select.qld__text-input--block.qld__field-width--full {
    max-width: $QLD-fluid-width__full;
}

input.qld__text-input--block.qld__field-width--3-quarters,
select.qld__text-input--block.qld__field-width--3-quarters {
    max-width: $QLD-fluid-width__full;
}

input.qld__text-input--block.qld__field-width--half,
select.qld__text-input--block.qld__field-width--half {
    max-width: $QLD-fluid-width__full;
}

input.qld__text-input--block.qld__field-width--1-quarter,
select.qld__text-input--block.qld__field-width--1-quarter {
    max-width: $QLD-fluid-width__full;
}

@include QLD-media (md) {
    input.qld__text-input--block.qld__field-width--1-quarter,
    select.qld__text-input--block.qld__field-width--1-quarter {
        max-width:$QLD-fluid-width__1-quarter;
    }

    input.qld__text-input--block.qld__field-width--half,
    select.qld__text-input--block.qld__field-width--half {
        max-width:$QLD-fluid-width__half;
    }

    input.qld__text-input--block.qld__field-width--3-quarters,
    select.qld__text-input--block.qld__field-width--3-quarters {
        max-width:$QLD-fluid-width__3-quarters;
    }
}