.qld__use-current-location-btn {
    border: 0;
    background-color: transparent;
    margin-top: 15px;
    position: relative;
    margin-left: 4px;
    padding-left: pxToRem(35);
    cursor: pointer;
    line-height: 20px;
    vertical-align: middle;
    color: var(--QLD-color-light__text);

    &:disabled {
        cursor: not-allowed;
    }

    &:before {
        content: "";
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 22px;
        height: 22px;
        border: 2px solid var(--QLD-color-light__heading);
    }

    &.active {
        &:before {
            background-image: QLD-svguri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
            '<path d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"/>' +
            '</svg>');
            background-size: 16px 14px;
            background-position: 1px 3px;
            background-repeat: no-repeat;
        }
    }
}