//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Pagination
//--------------------------------------------------------------------------

.qld__search-pagination {
    ul {
        @include sq-list-reset;
        display: flex;
        flex-wrap: wrap;

        li {
            @include QLD-space(margin, .5unit .75unit .5unit 0);


            &.ellipsis,
            &.first,
            &.last {
                display: none;

                @include QLD-media(md) {
                    display: block;
                }
            }

            &.active a{
                background-color: var(--QLD-color-light__action--primary);
                // border-color: var(--QLD-color-light__action--primary);
                box-shadow: inset 0 0 0 $QLD-border-width-thin var(--QLD-color-light__action--primary);
                color: var(--QLD-color-light__link--on-action);
                font-weight: bold;


                &:hover{
                    background-color: var(--QLD-color-light__action--primary-hover);
                    // border-color: var(--QLD-color-light__action--primary-hover);
                    box-shadow: inset 0 0 0 $QLD-border-width-default var(--QLD-color-light__action--primary-hover);
                    @include QLD-box-shadow(1);
                }

                &:active,
                &:focus {
                    background-color: var(--QLD-color-light__action--primary-hover);
                    // border-color: var(--QLD-color-light__action--primary-hover);
                    box-shadow: inset 0 0 0 $QLD-border-width-thin var(--QLD-color-light__action--primary-hover);
                }
            }

            &.next,
            &.prev{
                @include QLD-underline('light','noUnderline');
            }

            &.next,
            &.prev,
            &.ellipsis{
                a{
                    box-shadow: none;
                    @include QLD-media(lg) {
                        width: auto;
                    }
                    
                    &:hover{
                        span {
                            text-decoration: underline;
                        }  
                    }

                    &:active,
                    &:focus {
                        text-decoration: underline;
                    }
                }
                
                span{
                    @include QLD-sronly();
                    @include QLD-media(lg) {
                        position: relative;
                        width: auto;
                        height: auto;
                        overflow: initial;
                    }
                }
            }

            @include QLD-media(lg) {
                &.next span{
                    @include QLD-space(margin-right, .5unit);
                }
                &.prev span{
                    @include QLD-space(margin-left, .5unit);
                }
            }

            &.ellipsis svg{
                vertical-align: middle;
            }
        }

        .mid, .num {
            a {
                &:active,
                &:focus {
                    color: var(--QLD-color-dark__link);
                    background-color: var(--QLD-color-light__action--secondary);
                    // border: $QLD-border-width-default solid var(--QLD-color-light__action--secondary);
                    box-shadow: inset 0 0 0 $QLD-border-width-default var(--QLD-color-light__action--secondary);
                }
            }
        }

        a {
            text-decoration: none;
            @include QLD-space(height, 2unit);
            @include QLD-space(min-width, 2unit);
            @include QLD-space(padding, 0 0.625unit);
            align-items: center;
            background-color: transparent;
            border-radius: $QLD-border-radius-xs;
            color: var(--QLD-color-light__link);
            background-color: $QLD-color-neutral--white;
            display: inline-flex;
            justify-content: center;
            @include QLD-space(line-height, 1.25unit);
            box-shadow: inset 0 0 0 $QLD-border-width-thin $QLD-color-neutral--lighter;

            &:hover{
                color: var(--QLD-color-light__link);
                outline: none;
                text-decoration: none;
                box-shadow: inset 0 0 0 $QLD-border-width-default var(--QLD-color-light__action--secondary);
            }

            &[rel] {
                color: var(--QLD-color-light__action--primary);
            }

        }

        span {
            @include QLD-space(height, 2unit);
            @include QLD-space(width, 2unit);
            align-items: center;
            color: var(--QLD-color-light__action--primary);
            display: inline-flex;
            justify-content: center;
        }
    }

    .qld__body--dark & {

        li{
            &.active a{
                background-color: var(--QLD-color-dark__action--primary);
                color: var(--QLD-color-dark__link--on-action);

                &:hover{
                    background-color: var(--QLD-color-dark__action--primary-hover);
                    // border-color: var(--QLD-color-dark__action--primary-hover);
                    box-shadow: inset 0 0 0 $QLD-border-width-default var(--QLD-color-dark__action--primary-hover);
                }

                &:active,
                &:focus {
                    @include QLD-focus( 'dark' );
                    color: var(--QLD-color-dark__link);
                    background-color: var(--QLD-color-dark__action--primary-hover);
                    // border-color: var(--QLD-color-dark__action--primary-hover);
                    box-shadow: inset 0 0 0 $QLD-border-width-thin var(--QLD-color-dark__action--primary-hover);
                    
                }
            }

            &.next,
            &.prev,
            &.ellipsis{
                a{
                    &:hover{
                        color: var(--QLD-color-dark__link);
                        box-shadow: none;
                        span {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .mid, .num {
                a {
                    &:active,
                    &:focus {
                        color: var(--QLD-color-light__link);
                        background-color: var(--QLD-color-dark__action--secondary);
                        // border-color: var(--QLD-color-dark__action--secondary);
                        box-shadow: inset 0 0 0 $QLD-border-width-thin var(--QLD-color-dark__action--secondary);
                    }
                }
            }
        }

        a{
            background-color: var(--QLD-color-dark__background);
            // border-color: var(--QLD-color-dark__border);
            box-shadow: inset 0 0 0 $QLD-border-width-thin var(--QLD-color-dark__border);
            text-decoration: none;
            &:hover{
                color: var(--QLD-color-dark__link);
                // border-color: var(--QLD-color-dark__action--secondary);
                box-shadow: inset 0 0 0 $QLD-border-width-default var(--QLD-color-dark__action--secondary);
                outline: none;
                text-decoration: none;
            }
        }

        span{
            color: var(--QLD-color-dark__link);
        }
    }
        
}