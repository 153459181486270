//THIS IS A TEST NPM UPDATE

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.

@import "imports/functions.scss";
@import "imports/mixins.scss";
@import "imports/variables.scss";
@import "imports/placeholders.scss";

// Libraries

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~highlight.js/styles/github.css';
@import '~datatables.net-dt/css/jquery.dataTables.css';



// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    @include sq-border-box;
    image-rendering: -webkit-optimize-contrast;
}

// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "imports/utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: var(--QLD-color-dark__text--lighter);
    text-shadow: none;
}

::selection {
    color: var(--QLD-color-light__text);
    background: var(--QLD-color-dark__text--lighter);
    text-shadow: none;
}

html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    font-size: $base-font-scale + em;
    line-height: 1.5;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $QLD-font;
    color: var(--QLD-color-light__text);
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

img {
    max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.qld__body fieldset, fieldset {
    padding:0;
}


// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}


/*
--------------------
Components
--------------------
*/

@import "../components/_global/css/admin/component.scss";
@import "../components/_global/css/body/component.scss";
@import "../components/_global/css/btn/component.scss";
@import "../components/_global/css/cta_links/component.scss";
@import "../components/_global/css/direction_links/component.scss";
@import "../components/_global/css/example/component.scss";
@import "../components/_global/css/flex/flexbox-utility-classes.scss";
@import "../components/_global/css/grid-12/component.scss";
@import "../components/_global/css/headings/component.scss";
@import "../components/_global/css/icons/component.scss";
@import "../components/_global/css/img/images.scss";
@import "../components/_global/css/link_columns/component.scss";
@import "../components/_global/css/link_list/component.scss";
@import "../components/_global/css/links/components.scss";
@import "../components/_global/css/modal/component.scss";
@import "../components/_global/css/slick/component.scss";
@import "../components/_global/css/table/component.scss";
@import "../components/_global/css/tabs/component.scss";
@import "../components/_global/css/tags/component.scss";
@import "../components/_global/css/user_location/component.scss";
@import "../components/_global/css/version/component.scss";
@import "../components/_global/css/forms/control_inputs/component.scss";
@import "../components/_global/css/forms/general/component.scss";
@import "../components/_global/css/forms/select/component.scss";
@import "../components/_global/css/forms/text/component.scss";
@import "../components/_global/css/global.scss";
@import "../components/_template/css/component.scss";
@import "../components/a-z_listing/css/component.scss";
@import "../components/abstract/css/component.scss";
@import "../components/accordion/css/component.scss";
@import "../components/banner_advanced/css/component.scss";
@import "../components/banner_basic/css/component.scss";
@import "../components/banner_intermediate/css/component.scss";
@import "../components/banner/css/component.scss";
@import "../components/basic_search/css/component.scss";
@import "../components/body/css/component.scss";
@import "../components/breadcrumbs/css/component.scss";
@import "../components/callout/css/component.scss";
@import "../components/card_feature/css/component.scss";
@import "../components/card_multi_action/css/component.scss";
@import "../components/card_no_action/css/component.scss";
@import "../components/card_single_action/css/component.scss";
@import "../components/code/css/component.scss";
@import "../components/data_table/css/component.scss";
@import "../components/file_upload/css/component.scss";
@import "../components/footer/css/component.scss";
@import "../components/global_alert/css/component.scss";
@import "../components/header/css/component.scss";
@import "../components/horizontal_rule/css/component.scss";
@import "../components/in_page_navigation/css/component.scss";
@import "../components/internal_navigation/css/component.scss";
@import "../components/left_hand_navigation/css/component.scss";
@import "../components/loading_spinner/css/component.scss";
@import "../components/main_navigation/css/component.scss";
@import "../components/mega_main_navigation/css/component.scss";
@import "../components/multi_column/css/component.scss";
@import "../components/page_alert/css/component.scss";
@import "../components/pagination/css/component.scss";
@import "../components/promo_panel/css/component.scss";
@import "../components/search_box/css/component.scss";
@import "../components/tab/css/component.scss";
@import "../components/updated_date/css/component.scss";
@import "../components/widgets/css/component.scss";
