/*
 * Mobile layout
 */

body {
    z-index: 1;
}

.qld__table__attributes {
    font-size: 0.9em;

    .qld__model_field {
        width: 100%;
    }

    textarea {
        height: 100px;
    }
}

/*
 * Tablet layout
 */

@media screen and (min-width: md) {
    body {
        z-index: 2;
    }
}


/*
 * Desktop layout
 */

@media screen and (min-width: lg) {
    body {
        z-index: 3;
    }
}