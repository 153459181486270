.qld__display-flex {
    display: flex;
}

.qld__display-inline-flex {
    display: inline-flex;
}

.qld__flex-fill {
    flex: 1 1 auto;
}

.qld__flex-row {
    flex-direction: row;
}

.qld__flex-column {
    flex-direction: column;
}

.qld__flex-row-reverse {
    flex-direction: row-reverse;
}

.qld__flex-column-reverse {
    flex-direction: column-reverse;
}

.qld__flex-grow-0 {
    flex-grow: 0;
}

.qld__flex-grow-1 {
    flex-grow: 1;
}

.qld__flex-shrink-0 {
    flex-shrink: 0;
}

.qld__flex-shrink-1 {
    flex-shrink: 1;
}

.qld__flex-wrap {
    flex-wrap: wrap;
}

.qld__flex-nowrap {
    flex-wrap: nowrap;
}

.qld__flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.qld__justify-content-start {
    justify-content: flex-start;
}

.qld__justify-content-end {
    justify-content: flex-end;
}

.qld__justify-content-center {
    justify-content: center;
}

.qld__justify-content-between {
    justify-content: space-between;
}

.qld__justify-content-around {
    justify-content: space-around;
}

.qld__justify-content-evenly {
    justify-content: space-evenly;
}

.qld__align-items-start {
    align-items: flex-start;
}

.qld__align-items-end {
    align-items: flex-end;
}

.qld__align-items-center {
    align-items: center;
}

.qld__align-items-baseline {
    align-items: baseline;
}

.qld__align-items-stretch {
    align-items: stretch;
}

.qld__align-content-start {
    align-content: flex-start;
}

.qld__align-content-end {
    align-content: flex-end;
}

.qld__align-content-center {
    align-content: center;
}

.qld__align-content-between {
    align-content: space-between;
}

.qld__align-content-around {
    align-content: space-around;
}

.qld__align-content-stretch {
    align-content: stretch;
}

.qld__align-self-auto {
    align-self: auto;
}

.qld__align-self-start {
    align-self: flex-start;
}

.qld__align-self-end {
    align-self: flex-end;
}

.qld__align-self-center {
    align-self: center;
}

.qld__align-self-baseline {
    align-self: baseline;
}

.qld__align-self-stretch {
    align-self: stretch;
}

.qld__order-first {
    order: -1;
}

.qld__order-0 {
    order: 0;
}

.qld__order-1 {
    order: 1;
}

.qld__order-2 {
    order: 2;
}

.qld__order-3 {
    order: 3;
}

.qld__order-4 {
    order: 4;
}

.qld__order-5 {
    order: 5;
}

.qld__order-last {
    order: 6;
}



@include QLD-media(sm) {

    .qld__display-sm-flex {
        display: flex;
    }

    .qld__display-sm-inline-flex {
        display: inline-flex;
    }

    .qld__flex-sm-fill {
        flex: 1 1 auto;
    }

    .qld__flex-sm-row {
        flex-direction: row;
    }

    .qld__flex-sm-column {
        flex-direction: column;
    }

    .qld__flex-sm-row-reverse {
        flex-direction: row-reverse;
    }

    .qld__flex-sm-column-reverse {
        flex-direction: column-reverse;
    }

    .qld__flex-sm-grow-0 {
        flex-grow: 0;
    }

    .qld__flex-sm-grow-1 {
        flex-grow: 1;
    }

    .qld__flex-sm-shrink-0 {
        flex-shrink: 0;
    }

    .qld__flex-sm-shrink-1 {
        flex-shrink: 1;
    }

    .qld__flex-sm-wrap {
        flex-wrap: wrap;
    }

    .qld__flex-sm-nowrap {
        flex-wrap: nowrap;
    }

    .qld__flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .qld__justify-content-sm-start {
        justify-content: flex-start;
    }

    .qld__justify-content-sm-end {
        justify-content: flex-end;
    }

    .qld__justify-content-sm-center {
        justify-content: center;
    }

    .qld__justify-content-sm-between {
        justify-content: space-between;
    }

    .qld__justify-content-sm-around {
        justify-content: space-around;
    }

    .qld__justify-content-sm-evenly {
        justify-content: space-evenly;
    }

    .qld__align-items-sm-start {
        align-items: flex-start;
    }

    .qld__align-items-sm-end {
        align-items: flex-end;
    }

    .qld__align-items-sm-center {
        align-items: center;
    }

    .qld__align-items-sm-baseline {
        align-items: baseline;
    }

    .qld__align-items-sm-stretch {
        align-items: stretch;
    }

    .qld__align-content-sm-start {
        align-content: flex-start;
    }

    .qld__align-content-sm-end {
        align-content: flex-end;
    }

    .qld__align-content-sm-center {
        align-content: center;
    }

    .qld__align-content-sm-between {
        align-content: space-between;
    }

    .qld__align-content-sm-around {
        align-content: space-around;
    }

    .qld__align-content-sm-stretch {
        align-content: stretch;
    }

    .qld__align-self-sm-auto {
        align-self: auto;
    }

    .qld__align-self-sm-start {
        align-self: flex-start;
    }

    .qld__align-self-sm-end {
        align-self: flex-end;
    }

    .qld__align-self-sm-center {
        align-self: center;
    }

    .qld__align-self-sm-baseline {
        align-self: baseline;
    }

    .qld__align-self-sm-stretch {
        align-self: stretch;
    }

    .qld__order-sm-first {
        order: -1;
    }

    .qld__order-sm-0 {
        order: 0;
    }

    .qld__order-sm-1 {
        order: 1;
    }

    .qld__order-sm-2 {
        order: 2;
    }

    .qld__order-sm-3 {
        order: 3;
    }

    .qld__order-sm-4 {
        order: 4;
    }

    .qld__order-sm-5 {
        order: 5;
    }

    .qld__order-sm-last {
        order: 6;
    }

}

@include QLD-media(md) {
    .qld__display-md-flex {
        display: flex;
    }

    .qld__display-md-inline-flex {
        display: inline-flex;
    }


    .qld__flex-md-fill {
        flex: 1 1 auto;
    }

    .qld__flex-md-row {
        flex-direction: row;
    }

    .qld__flex-md-column {
        flex-direction: column;
    }

    .qld__flex-md-row-reverse {
        flex-direction: row-reverse;
    }

    .qld__flex-md-column-reverse {
        flex-direction: column-reverse;
    }

    .qld__flex-md-grow-0 {
        flex-grow: 0;
    }

    .qld__flex-md-grow-1 {
        flex-grow: 1;
    }

    .qld__flex-md-shrink-0 {
        flex-shrink: 0;
    }

    .qld__flex-md-shrink-1 {
        flex-shrink: 1;
    }

    .qld__flex-md-wrap {
        flex-wrap: wrap;
    }

    .qld__flex-md-nowrap {
        flex-wrap: nowrap;
    }

    .qld__flex-md-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .qld__justify-content-md-start {
        justify-content: flex-start;
    }

    .qld__justify-content-md-end {
        justify-content: flex-end;
    }

    .qld__justify-content-md-center {
        justify-content: center;
    }

    .qld__justify-content-md-between {
        justify-content: space-between;
    }

    .qld__justify-content-md-around {
        justify-content: space-around;
    }

    .qld__justify-content-md-evenly {
        justify-content: space-evenly;
    }

    .qld__align-items-md-start {
        align-items: flex-start;
    }

    .qld__align-items-md-end {
        align-items: flex-end;
    }

    .qld__align-items-md-center {
        align-items: center;
    }

    .qld__align-items-md-baseline {
        align-items: baseline;
    }

    .qld__align-items-md-stretch {
        align-items: stretch;
    }

    .qld__align-content-md-start {
        align-content: flex-start;
    }

    .qld__align-content-md-end {
        align-content: flex-end;
    }

    .qld__align-content-md-center {
        align-content: center;
    }

    .qld__align-content-md-between {
        align-content: space-between;
    }

    .qld__align-content-md-around {
        align-content: space-around;
    }

    .qld__align-content-md-stretch {
        align-content: stretch;
    }

    .qld__align-self-md-auto {
        align-self: auto;
    }

    .qld__align-self-md-start {
        align-self: flex-start;
    }

    .qld__align-self-md-end {
        align-self: flex-end;
    }

    .qld__align-self-md-center {
        align-self: center;
    }

    .qld__align-self-md-baseline {
        align-self: baseline;
    }

    .qld__align-self-md-stretch {
        align-self: stretch;
    }

    .qld__order-md-first {
        order: -1;
    }

    .qld__order-md-0 {
        order: 0;
    }

    .qld__order-md-1 {
        order: 1;
    }

    .qld__order-md-2 {
        order: 2;
    }

    .qld__order-md-3 {
        order: 3;
    }

    .qld__order-md-4 {
        order: 4;
    }

    .qld__order-md-5 {
        order: 5;
    }

    .qld__order-md-last {
        order: 6;
    }

}

@include QLD-media(lg) {
    .qld__display-lg-flex {
        display: flex;
    }

    .qld__display-lg-inline-flex {
        display: inline-flex;
    }

    .qld__flex-lg-fill {
        flex: 1 1 auto;
    }

    .qld__flex-lg-row {
        flex-direction: row;
    }

    .qld__flex-lg-column {
        flex-direction: column;
    }

    .qld__flex-lg-row-reverse {
        flex-direction: row-reverse;
    }

    .qld__flex-lg-column-reverse {
        flex-direction: column-reverse;
    }

    .qld__flex-lg-grow-0 {
        flex-grow: 0;
    }

    .qld__flex-lg-grow-1 {
        flex-grow: 1;
    }

    .qld__flex-lg-shrink-0 {
        flex-shrink: 0;
    }

    .qld__flex-lg-shrink-1 {
        flex-shrink: 1;
    }

    .qld__flex-lg-wrap {
        flex-wrap: wrap;
    }

    .qld__flex-lg-nowrap {
        flex-wrap: nowrap;
    }

    .qld__flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .qld__justify-content-lg-start {
        justify-content: flex-start;
    }

    .qld__justify-content-lg-end {
        justify-content: flex-end;
    }

    .qld__justify-content-lg-center {
        justify-content: center;
    }

    .qld__justify-content-lg-between {
        justify-content: space-between;
    }

    .qld__justify-content-lg-around {
        justify-content: space-around;
    }

    .qld__justify-content-lg-evenly {
        justify-content: space-evenly;
    }

    .qld__align-items-lg-start {
        align-items: flex-start;
    }

    .qld__align-items-lg-end {
        align-items: flex-end;
    }

    .qld__align-items-lg-center {
        align-items: center;
    }

    .qld__align-items-lg-baseline {
        align-items: baseline;
    }

    .qld__align-items-lg-stretch {
        align-items: stretch;
    }

    .qld__align-content-lg-start {
        align-content: flex-start;
    }

    .qld__align-content-lg-end {
        align-content: flex-end;
    }

    .qld__align-content-lg-center {
        align-content: center;
    }

    .qld__align-content-lg-between {
        align-content: space-between;
    }

    .qld__align-content-lg-around {
        align-content: space-around;
    }

    .qld__align-content-lg-stretch {
        align-content: stretch;
    }

    .qld__align-self-lg-auto {
        align-self: auto;
    }

    .qld__align-self-lg-start {
        align-self: flex-start;
    }

    .qld__align-self-lg-end {
        align-self: flex-end;
    }

    .qld__align-self-lg-center {
        align-self: center;
    }

    .qld__align-self-lg-baseline {
        align-self: baseline;
    }

    .qld__align-self-lg-stretch {
        align-self: stretch;
    }

    .qld__order-lg-first {
        order: -1;
    }

    .qld__order-lg-0 {
        order: 0;
    }

    .qld__order-lg-1 {
        order: 1;
    }

    .qld__order-lg-2 {
        order: 2;
    }

    .qld__order-lg-3 {
        order: 3;
    }

    .qld__order-lg-4 {
        order: 4;
    }

    .qld__order-lg-5 {
        order: 5;
    }

    .qld__order-lg-last {
        order: 6;
    }

}

@include QLD-media(xl) {
    .qld__display-xl-flex {
        display: flex;
    }

    .qld__display-xl-inline-flex {
        display: inline-flex;
    }

    .qld__flex-xl-fill {
        flex: 1 1 auto;
    }

    .qld__flex-xl-row {
        flex-direction: row;
    }

    .qld__flex-xl-column {
        flex-direction: column;
    }

    .qld__flex-xl-row-reverse {
        flex-direction: row-reverse;
    }

    .qld__flex-xl-column-reverse {
        flex-direction: column-reverse;
    }

    .qld__flex-xl-grow-0 {
        flex-grow: 0;
    }

    .qld__flex-xl-grow-1 {
        flex-grow: 1;
    }

    .qld__flex-xl-shrink-0 {
        flex-shrink: 0;
    }

    .qld__flex-xl-shrink-1 {
        flex-shrink: 1;
    }

    .qld__flex-xl-wrap {
        flex-wrap: wrap;
    }

    .qld__flex-xl-nowrap {
        flex-wrap: nowrap;
    }

    .qld__flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .qld__justify-content-xl-start {
        justify-content: flex-start;
    }

    .qld__justify-content-xl-end {
        justify-content: flex-end;
    }

    .qld__justify-content-xl-center {
        justify-content: center;
    }

    .qld__justify-content-xl-between {
        justify-content: space-between;
    }

    .qld__justify-content-xl-around {
        justify-content: space-around;
    }

    .qld__justify-content-xl-evenly {
        justify-content: space-evenly;
    }

    .qld__align-items-xl-start {
        align-items: flex-start;
    }

    .qld__align-items-xl-end {
        align-items: flex-end;
    }

    .qld__align-items-xl-center {
        align-items: center;
    }

    .qld__align-items-xl-baseline {
        align-items: baseline;
    }

    .qld__align-items-xl-stretch {
        align-items: stretch;
    }

    .qld__align-content-xl-start {
        align-content: flex-start;
    }

    .qld__align-content-xl-end {
        align-content: flex-end;
    }

    .qld__align-content-xl-center {
        align-content: center;
    }

    .qld__align-content-xl-between {
        align-content: space-between;
    }

    .qld__align-content-xl-around {
        align-content: space-around;
    }

    .qld__align-content-xl-stretch {
        align-content: stretch;
    }

    .qld__align-self-xl-auto {
        align-self: auto;
    }

    .qld__align-self-xl-start {
        align-self: flex-start;
    }

    .qld__align-self-xl-end {
        align-self: flex-end;
    }

    .qld__align-self-xl-center {
        align-self: center;
    }

    .qld__align-self-xl-baseline {
        align-self: baseline;
    }

    .qld__align-self-xl-stretch {
        align-self: stretch;
    }

    .qld__order-xl-first {
        order: -1;
    }

    .qld__order-xl-0 {
        order: 0;
    }

    .qld__order-xl-1 {
        order: 1;
    }

    .qld__order-xl-2 {
        order: 2;
    }

    .qld__order-xl-3 {
        order: 3;
    }

    .qld__order-xl-4 {
        order: 4;
    }

    .qld__order-xl-5 {
        order: 5;
    }

    .qld__order-xl-last {
        order: 6;
    }

}