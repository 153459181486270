//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Slick Slider
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.slick-slider{
    .slick-track{
        display: flex !important;
    }

    .slick-slide{
        height: inherit;

        > div{
            height: 100%;
        }

        li{
            height: 100%;
        }
        .qld__card{
            height: 100%;
        }
    }

}
