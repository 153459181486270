//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// {{component_name}}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__form-file-wrapper {
    margin-top: pxToRem(8);

    .qld__form-file-info {
        margin-left: 0;
        min-width: pxToRem(250);
        white-space: nowrap;

        @include QLD-media(sm) {
            margin-left: pxToRem(16);
        }
        
        p {
            font-weight: 600;
            font-size: pxToRem(14);
            line-height: pxToRem(16);
            max-width: none;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        span {
            margin-top: pxToRem(8);
            white-space: normal;
        }
    }

    p.qld__file-input-title {
        color: var(--QLD-color-light__heading);
        font-weight: 600;
        font-size: pxToRem(20);
        line-height: pxToRem(24);
        margin: 0 auto pxToRem(24);

        .qld__body--dark & {
            color: var(--QLD-color-dark__heading);
        }
    
        &::first-letter {
            text-transform: capitalize;
        }
    
        span {
            display: none;
            @include QLD-media(lg) {
                display: inline;
            }
        }
    
        @include QLD-media(lg) {
            margin: pxToRem(24) auto;
        }
    }

    .qld__body--dark & {
        .qld__file-input-title {
            color: var(--QLD-color-dark__heading);
        }
        .qld__form-file-info {
            &:not(.qld__form-file--success) & {
                p,
                span {
                    color: var(--QLD-color-dark__text);
                }
            }
        }
    }

    .qld__file-input-label {
        font-weight: 400;
    }

    .qld__form-file-dropzone {
        border: 2px dashed var(--QLD-color-light__border--alt);
        border-radius: var(--QLD-border-radius-xs);
        padding: pxToRem(24) 0;
        text-align: center;
    
        &:not(.qld__input--error) {
            .qld__body--dark & {
                background-color: var(--QLD-color-dark__background);
                border-color: var(--QLD-color-dark__border)--alt;
    
                .qld__loading-spinner {
                    background-color: var(--QLD-color-dark__background);
                }
                
                .qld__loading_spinner-label {
                    color: var(--QLD-color-dark__heading);
                }
    
                .qld__file-input-label {
                    border-color: var(--QLD-color-dark__action--secondary);
                    color: var(--QLD-color-dark__heading);
                    
                    &:hover, 
                    &:focus,
                    &:active {
                        background-color: transparent;
                        border-color: $QLD-color-neutral--lightest;
                        text-decoration-color: var(--QLD-color-dark__heading);
                    }
                }
    
                p.qld__file-input-title {
                    color: var(--QLD-color-dark__heading);
                }
            }
        }

        &.qld__input--error:not(.qld__form-file-dropzone--updating) {
            background-color: $QLD-color-status__error--lightest;
            border-color: $QLD-color-status__error;
    
            p.qld__file-input-title {
                color: var(--QLD-color-light__text--lighter);
            }
    
            .qld__file-input-label {
                border-color: var(--QLD-color-light__action--secondary);
                color: var(--QLD-color-light__action--primary);
    
                &:hover,
                &:active,
                &:focus {
                    border-color: var(--QLD-color-light__action--secondary-hover);
                    text-decoration-color: var(--QLD-color-light__action--primary);
                }
            }

            .qld__file-input-icon {
                background-color: $QLD-color-status__error--lighter;
                color: $QLD-color-status__error;
            }
        }

        &--dragged-over:not(.qld__form-file-dropzone--updating) {
            background-color: $QLD-color-neutral--lightest;
            border-color: var(--QLD-color-light__action--primary-hover);
            
            .qld__body--dark & {
                background-color: var(--QLD-color-dark__background--shade);
                border-color: var(--QLD-color-dark__action--secondary);
    
                p.qld__file-input-title,
                .qld__file-input-constraints span,
                .qld__file-input-label {
                    color: var(--QLD-color-dark__link);
                }
                label.qld__file-input-label {
                    border-color: var(--QLD-color-dark__action--secondary);
                    &:hover,
                    &:active,
                    &:focus {
                        border-color: var(--QLD-color-dark__action--secondary);
                        text-decoration-color: var(--QLD-color-dark__heading);
                    }
                }
                .qld__file-input-icon {
                    background-color: var(--QLD-color-dark__background);
                    color: var(--QLD-color-dark__link);
                }
            }
        }
    
        &--disabled:not(.qld__input--error),
        &--updating:not(.qld__input--error) {
            color: var(--QLD-color-light__text--lighter);
    
            .qld__file-input-label {
                border-color: var(--QLD-color-light__background--alt);
                color: var(--QLD-color-light__text--lighter);
                pointer-events: none;
    
                .qld__body--dark & { 
                    color: var(--QLD-color-dark__heading);
                    border-color: var(--QLD-color-light__background--alt-shade);
                }
            }
        }
    
        @include QLD-media(lg) {
            padding: pxToRem(32) pxToRem(24);
        }
    }

    .qld__form-file-preview {
        div.qld__form-file {
            align-items: center;
            border: 2px solid $QLD-color-neutral--lightest;
            border-radius: var(--QLD-border-radius-xs);
            display: flex;
            flex-wrap: wrap;
            margin: pxToRem(16) 0 0;
            padding: pxToRem(16);
        
            .qld__body--dark & {
                background-color: var(--QLD-color-dark__background--shade);
                border-color: var(--QLD-color-dark__border);
            }
        
            .qld__form-file-delete-btn {
                i {
                    color: var(--QLD-color-light__action--secondary);

                    .qld__body--dark & {
                        color: var(--QLD-color-dark__action--secondary);
                    }
                }
        
                &:hover,
                &:focus,
                &:active {
                    background-color: transparent;
                    text-decoration-color: var(--QLD-color-light__link);
        
                    .qld__body--dark & { 
                        text-decoration-color: var(--QLD-color-dark__heading);
                    }
                }
            }
        
            &--complete {
                background-color: $QLD-color-neutral--lightest;
                border-color: $QLD-color-neutral--lighter;
                
                .qld__body--dark & {
                    background-color: var(--QLD-color-dark__background--shade);
                    border-color: var(--QLD-color-dark__border);
        
                    p,span {
                        color: var(--QLD-color-dark__heading);
                    }
        
                    button.qld__form-file-delete-btn {
                        color: var(--QLD-color-dark__heading);
                        i {
                            color: var(--QLD-color-dark__action--secondary);
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            background-color: transparent;
                            text-decoration-color: var(--QLD-color-dark__text);
                            i {
                                color: var(--QLD-color-dark__action--secondary);
                            }
                        }
                    }
                }
            }
        
            &--error {
                background-color: $QLD-color-status__error--lightest;
                border-color: $QLD-color-status__error;
        
                .qld__body--dark & {
                    background-color: $QLD-color-status__error--lightest;
                    border-color: $QLD-color-status__error;

                    .qld__form-file-info-name {
                        color: var(--QLD-color-light__heading);
                    }
                    .qld__form-file-delete-btn {
                        color: var(--QLD-color-light__link);
                        text-decoration-color: var(--QLD-color-light__action--primary);
        
                        i {
                            color: var(--QLD-color-light__action--secondary);
                        }
        
                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--QLD-color-light__link);
                            text-decoration-color: var(--QLD-color-light__action--primary);
                        }
                    }
                }
            }
            
            &--updating {
                background-color: var(--QLD-color-dark__heading);
            
                .qld__body--dark & {
                    background-color: var(--QLD-color-dark__background);
                    border-color: var(--QLD-color-dark__border);
        
                    p,span {
                        color: var(--QLD-color-dark__heading);
                    }
                }
            }

            &--success {
                background-color: $QLD-color-status__success--lightest;
                border-color: $QLD-color-status__success;
        
                .qld__body--dark & {
                    background-color: $QLD-color-status__success--lightest;
                    border-color: $QLD-color-status__success;

                    .qld__form-file-loader {
                        i {
                            color: var(--QLD-color-light__action--secondary);
                        }
                    }
                    .qld__form-file-info-name {
                        color: var(--QLD-color-light__heading);
                    }
                    .qld__form-file-delete-btn {
                        color: var(--QLD-color-light__action--primary);
                        i {
                            color: $QLD-color-status__success;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            background-color: transparent;
                            text-decoration-color: var(--QLD-color-light__link);
                        }
                    }
                }
            }
        
            @include QLD-media(md) {
                flex-wrap: nowrap;
            }
        
            @include QLD-media(md) {
                padding: pxToRem(24);
            }
        }
    }
}

.qld__file-input-constraints {
    span {
        display: block;
    }
    .qld__body--dark & {
        color: var(--QLD-color-dark__heading);
    }
    span {
        .qld__input--error & {
            color: var(--QLD-color-light__text--lighter);
        }
        .qld__form-file-dropzone--updating & {
            color: inherit;
        }
    }
}

.qld__form-file-info-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    @include QLD-media(sm) {
        flex-wrap: nowrap;
    }
}

.qld__file-input-icon {
    background-color: $QLD-color-neutral--lightest;
    border-radius: pxToRem(32);
    color: var(--QLD-color-light__action--secondary);
    display: none;
    line-height: pxToRem(20);
    min-height: pxToRem(64);
    min-width: pxToRem(64);

    .qld__loading_spinner {
        display: none;
        font-size: inherit;
    }

    .qld__body--dark & {
        background-color: var(--QLD-color-dark__background--shade);
        color: var(--QLD-color-dark__action--secondary);
    }

    .qld__form-file-dropzone--dragged-over & {
        background-color: var(--QLD-color-dark__heading);

        .qld__body--dark & {
            background-color: var(--QLD-color-dark__background);
            color: var(--QLD-color-dark__link);
        }
    }

    .qld__form-file-dropzone--disabled & {
        color: var(--QLD-color-light__text--lighter);
    }

    .qld__form-file-dropzone--updating & {
        background-color: transparent;
        color: var(--QLD-color-light__text--lighter);

        i {
            display: none;
        }

        .qld__loading_spinner {
            display: flex;
            
            .qld__loading_spinner-label {
                font-size: pxToRem(16);
            }
        }
    } 
    
    @include QLD-media(lg) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

.qld__form-file-info-status {
    align-content: center;
    color: var(--QLD-color-light__text--lighter);
    display: flex;
    font-weight:400;
    font-size: pxToRem(14);
    line-height: pxToRem(20);

    .qld__body--dark & {
        color: var(--QLD-color-dark__heading);
    }

    .qld__form-file--success & {
        color: var(--QLD-color-light__text--lighter);
    }

    .qld__form-file--error & {
        color: $QLD-color-status__error;
    }

    svg {
        flex-shrink: 0;
        margin-right: 4px;
    }
}

.qld__form-file-actions {
    flex-shrink: 0;
    margin-left: auto;
    width: 100%;

    @include QLD-media(md) {
        width: auto;
    }

    button.qld__form-file-delete-btn {
        padding: 0;
        min-width: pxToRem(100);
        margin-top: pxToRem(16);

        @include QLD-media(md) {
            margin-top: 0;
            padding: pxToRem(10) pxToRem(24);
        }
    }
}

.qld__form-file-loader {
    height: pxToRem(32); 
    width: pxToRem(32);
    margin-bottom: pxToRem(16);
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    @include QLD-media(sm) {
        margin-bottom: 0;
    }

    .qld__body--dark & {
        i {
            color: var(--QLD-color-dark__action--secondary);
        }
    }

    i {
        color: var(--QLD-color-light__action--secondary);
        width: 100%;

        .qld__form-file--error & {
            color: $QLD-color-status__error;
        }
    }
}