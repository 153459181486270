//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Icons
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

svg.qld__icon{
    height: 1em;
    width: 1em;
    display: inline-block;
    align-self: center;
    vertical-align: middle;

    &--xs{
        font-size: $QLD-icon-size__xs;
    }

    &--sm{
        font-size: $QLD-icon-size__sm;
    }

    &--md{
        font-size: $QLD-icon-size__md;
    }

    &--lg{
        font-size: $QLD-icon-size__lg;
    }

    &--xl{
        font-size: $QLD-icon-size__xl;
    }

    &--xxl{
        font-size: $QLD-icon-size__xxl;
    }

    &--feature-lg{
        font-size: $QLD-icon-size__feature;
        border-radius: 50%;
        background-color: $QLD-color-neutral--lightest;

        .qld__body--dark & {
            background-color: var(--QLD-color-dark__background--shade);
        }

        .qld__body--alt & {
            background-color: var(--QLD-color-light__background--alt-shade);
        }

        .qld__body--light & {
            background-color: var(--QLD-color-light__background--shade);
        }

        .qld__body--dark-alt & {
            background-color: var(--QLD-color-dark__background--alt-shade);
        }
    }

    &--lead {
        @include QLD-space(margin-right, 0.5unit);
    }

    &--trail {
        @include QLD-space(margin-left, 0.5unit);
    }
}

i.qld__icon{
    font-size: $QLD-icon-size__xs;

    &--sm{
        font-size: $QLD-icon-size__sm;
    }
    &--md{
        font-size: $QLD-icon-size__md;
    }
    &--lg{
        font-size: $QLD-icon-size__lg;
    }

    &--xl{
        font-size: $QLD-font-size__feature;
    }

    &--feature-lg{
        font-size: $QLD-font-size__feature-lg;
    }

    &--lead {
        @include QLD-space(margin-right, 0.5unit);
    }

    &--trail {
        @include QLD-space(margin-left, 0.5unit);
    }
}

.qld__header__pre-header,
.qld__header__main,
.qld__body,
.qld__footer,
.qld__banner{

    .qld__icon{
        color: var(--QLD-color-light__heading);
        fill: var(--QLD-color-light__heading);
    }

    a .qld__icon{
        color: var(--QLD-color-light__action--secondary);
        fill: var(--QLD-color-light__action--secondary);
    }

    a:hover{
        .qld__icon{
            color: var(--QLD-color-light__action--secondary-hover);
            fill: var(--QLD-color-light__action--secondary-hover);
        }
    }

    &.qld__header__pre-header--dark-alt,
    &.qld__header__pre-header--dark,
    &.qld__body--dark,
	&.qld__body--dark-alt,
	&.qld__banner--dark,
	&.qld__banner--dark-alt,
	&.qld__header__main--dark,
	&.qld__header__main--dark-alt,
	&.qld__footer--dark,
	&.qld__footer--dark-alt{

        .qld__icon{
            color: var(--QLD-color-dark__heading);
            fill: var(--QLD-color-dark__heading);
        }

        a .qld__icon{
            color: var(--QLD-color-dark__action--secondary);
            fill: var(--QLD-color-dark__action--secondary);
        }

        a:hover{
            .qld__icon{
                color: var(--QLD-color-dark__action--secondary-hover);
                fill: var(--QLD-color-dark__action--secondary-hover);
            }
        }
    }    
}
    


//Font awesome fix to remove underline when link is on hover
.fa-light{
    &::before{
        display: inline-block;
    }
}