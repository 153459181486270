//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Multi column
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.text-columns {
    h2, h3 {
        &:first-child {
            @include QLD-space(margin-top, 3unit);
        }
    }
}