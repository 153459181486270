//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Body
//--------------------------------------------------------------------------------------------------------------------------------------------------------------


body {
    z-index: 1;

    @include QLD-media( md ) {
        z-index: 2;
    }

    @include QLD-media( lg ) {
        z-index: 3;
    }
}
