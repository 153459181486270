//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Internal Navigation
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

$QLD-side-nav-depth: 3 !default;
$QLD-side-nav-breakpoint: lg !default;

// Mobile side nav spacing
// .qld__side-nav {
//     @include QLD-space(margin-bottom, 2.5unit);
    
//     @include QLD-media(sm) {
//         @include QLD-space(margin-bottom, 0unit);
//     }
//     @include QLD-media(lg) {
//         @include QLD-space(margin-right, 1unit);
//     }
// }

.qld__side-nav {
    @include QLD-fontgrid(sm);
    font-family: $QLD-font;
    background-color: $QLD-color-neutral--lightest;
    border-radius: $QLD-border-radius;
    overflow: hidden;
    @include QLD-space(margin-bottom, 2.5unit);
    
    @include QLD-media(lg) {
        @include QLD-space(margin-bottom, 0unit);
        // @include QLD-space(margin-right, 1unit);
        border: 0;
    }
    
    @include QLD-media(xl) {
        @include QLD-space(margin-bottom, 0unit);
    }
    a,
    .active > span {
        @include QLD-space(padding, 1unit);
        @include QLD-fontgrid(xs);
        color: var(--QLD-color-light__text);
        display: block;
    }
    
    a {
        max-width: 100%;
        @include QLD-underline('light','noUnderline');
        
        &:hover {
            color: var(--QLD-color-light__link);
            background-color: $QLD-color-neutral--lightest;

            &:visited {
                color: var(--QLD-color-light__link);
                background-color: $QLD-color-neutral--lightest;
                text-decoration: none;
            }
        }
        
        @include QLD-focus();
    }

    a:visited {
        all: unset;
    }

    
    // Link list
    .qld__link-list {
        @include QLD-space(
            border-top,
            solid 0.0625unit var(--QLD-color-light__design-accent)
        );
        padding: 0;
        margin: 0 !important;
        
        li {
            margin: 0;

            &:first-child{
                a,
                span {
                    border-top-color: transparent;
                }
            }
        }

        a,
        span{
            border-top:$QLD-border-width-thin solid var(--QLD-color-light__border--alt);

            

        }

        .qld__link-list {
            @include QLD-space(border-top-width, 0unit);

            li{
                a,
                span{
                    border-top:$QLD-border-width-thin solid $QLD-color-neutral--lighter;
                }
            }
        }
    }
    
    // Accordion styles
    .qld__accordion__body {
        overflow: visible;
    }
    
    .qld__accordion__title {
        @include QLD-fontgrid(md, heading);
        color: var(--QLD-color-light__link);
        font-weight: normal;
        background-color: inherit;
        
        &:focus {
            outline-offset: -3px;
        }
        
        // Hide the accordion title without javascript
        .no-js & {
            display: none;
        }
        
        &::after {
            background-image: QLD-svg-with-fill($QLD-icon-chevron-up, var(--QLD-color-light__action--secondary));
            margin-top: -.8rem;
            @include QLD-space(height, 1.5unit);
        }
    }
    
    .qld__accordion__body-wrapper {
        border: 0;
        padding: 0;
    }
    
    // Desktop styles
    @include QLD-media($QLD-side-nav-breakpoint) {
        background: transparent;
        border-radius: 0;
        overflow: visible;
        
        // Open the menu on desktop
        .qld__accordion__body.qld__accordion--closed {
            display: block;
            height: auto;
        }
        
        a:hover {
            background-color: $QLD-color-neutral--lightest;
        }
        
        .qld__accordion__title {
            display: none;
        }
    }
}

// Content inside the side navigation accordion
.qld__side-nav__content {
    @include QLD-space(margin, 0 1unit);
    
    ul {
        @include QLD-side-nav-indent(3);
        @include QLD-side-nav-indent(3, ".active > span");
    }
    
    .active > span {
        font-weight: bold;
        color: var(--QLD-color-light__heading);
    }
    
    ul ul ul a,
    ul ul ul .active > span {
        &:before {
            content: " ";
            @include QLD-space(width, 1unit);
            @include QLD-space(height, 1unit);
            @include QLD-space(background-size, 1unit);
            @include QLD-space(margin-left, -1.5unit);
            display: block;
            float: left;
            background-repeat: no-repeat;
            background-image: QLD-svguri(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">' +
                    '<path fill="#{ var(--QLD-color-light__border) }" d="M2 10h12v1H2zM2 5h1v5H2z"/>' +
                    "</svg>"
            );
        }
    }
    
    & > ul > li {
        // border-bottom: 1px solid var(--QLD-color-light__border);
        
        &:last-of-type {
            border: none;
        }
    }
    
    @include QLD-media($QLD-side-nav-breakpoint) {
        margin: 0;
    }
}

// Side navigation title
.qld__side-nav .qld__sidenav__title {
    margin: 0;
    max-width: 100%;
    
    a {
        @include QLD-fontgrid(sm);
        color: var(--QLD-color-light__heading);
        @include QLD-space(
            border-bottom,
            solid 0.125unit var(--QLD-color-light__design-accent)
        );
        @include QLD-underline('light','noUnderline','default','noVisited');

        @include QLD-media(md) {
            font-weight: bold;
        }

        &:hover,
        &:focus {
            color: var(--QLD-color-light__link);
        }
    }
}

// Alternate colour variation
.qld__side-nav.qld__side-nav--alt,
.qld__body--alt .qld__side-nav{
    background-color: var(--QLD-color-light__background--alt-shade);
    
    .qld__accordion__title {
        color: var(--QLD-color-light__heading);
        background-color: inherit;
    }
    
    a:hover {
        background: var(--QLD-color-light__background--alt);
    }
    
    @include QLD-media($QLD-side-nav-breakpoint) {
        background: transparent;
        
        a:hover {
            background-color: var(--QLD-color-light__background--alt-shade);
        }
    }
    
    .qld__link-list {
        li {
            @include QLD-space(
                border-top,
                solid 0.0625unit var(--QLD-color-light__border--alt)
            );
        }
    }
}

// Dark colour variation
.qld__side-nav.qld__side-nav--dark,
.qld__body--dark .qld__side-nav {
    background: var(--QLD-color-dark__background--shade);
    
    .qld__accordion__title {
        color: var(--QLD-color-dark__heading);
        background-color: inherit;
    }
    
    a {
        color: var(--QLD-color-dark__text);
        @include QLD-underline('dark','noUnderline');
        &:hover {
            background: var(--QLD-color-dark__background--alt-shade);

            &:visited {
                color: var(--QLD-color-dark__text);
                text-decoration: none;
            }
        }

        &:visited {
            all: unset;
        }
        
        
        @include QLD-focus("dark");
    }
    
    .qld__side-nav__content {
        .active > span {
            color: var(--QLD-color-dark__text);
        }
        
        ul ul ul a:before,
        ul ul ul .active > span:before {
            background-image: QLD-svguri(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">' +
                    '<path fill="#{ var(--QLD-color-dark__border) }" d="M2 10h12v1H2zM2 5h1v5H2z"/>' +
                    "</svg>"
            );
        }
        
        & > ul > li {
            // border-bottom: 1px solid var(--QLD-color-dark__border);
            
            &:last-of-type{
                border: none;
            }
        }
    }
    
    .qld__sidenav__title a {
        border-color: var(--QLD-color-dark__design-accent);
        color: var(--QLD-color-dark__text);
        @include QLD-underline('light','noUnderline','default','noVisited');
    }
    
    @include QLD-media($QLD-side-nav-breakpoint) {
        background: transparent;
        
        a:hover {
            background-color: var(--QLD-color-dark__background--shade);
        }
        
    }
    
    .qld__link-list {
        @include QLD-space(
            border-top,
            solid 0.0625unit var(--QLD-color-dark__design-accent)
        );
        padding: 0;
        margin: 0 !important;
        
        li {
            margin: 0;
        }
        
        .qld__link-list {
            @include QLD-space(border-top-width, 0unit);
        }
    }
}

// Dark alternative colour variation
// Dark colour variation
.qld__side-nav.qld__side-nav--alt-dark,
.qld__body--dark-alt .qld__side-nav {
    background: var(--QLD-color-dark__background--alt-shade);
    
    .qld__accordion__title {
        color: var(--QLD-color-dark__heading);
        background-color: inherit;
    }
    
    a {
        color: var(--QLD-color-dark__text);
        @include QLD-underline('dark','noUnderline');
        &:hover {
            background: var(--QLD-color-dark__background--alt);

            &:visited {
                color: var(--QLD-color-dark__text);
                text-decoration: none;
            }
        }

        &:visited {
            all: unset;
        }
        
        @include QLD-focus("dark");
    }
    
    .qld__side-nav__content {
        .active > span {
            color: var(--QLD-color-dark__text);
        }
        
        ul ul ul a:before,
        ul ul ul .active > span:before {
            background-image: QLD-svguri(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">' +
                    '<path fill="#{ var(--QLD-color-dark__border) }" d="M2 10h12v1H2zM2 5h1v5H2z"/>' +
                    "</svg>"
            );
        }
        
        & > ul > li {
            border-color: var(--QLD-color-dark__border);
        }
    }
    
    .qld__sidenav__title a {
        border-color: var(--QLD-color-dark__design-accent);
        color: var(--QLD-color-dark__text)
    }
    
    @include QLD-media($QLD-side-nav-breakpoint) {
        background: transparent;
        
        a:hover {
            background-color: var(--QLD-color-dark__background--alt-shade);
        }
    }
    
    .qld__link-list {
        @include QLD-space(
            border-top,
            solid 0.0625unit var(--QLD-color-dark__design-accent)
        );
        padding: 0;
        margin: 0 !important;
        
        li {
            margin: 0;
        }
        
        .qld__link-list {
            @include QLD-space(border-top-width, 0unit);
        }
    }
}

// Print styles
@media print {
	.qld__side-nav {
        display: none !important;
        background: transparent !important;

        a {
            color: #000;
            text-decoration: underline;

            &:hover {
                background: transparent;
            }
        }

        .qld__accordion__title {
            display: none;
        }
	}

	.qld__side-nav__content {

        ul ul ul a:before {
            background-image: QLD-svguri( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">' +
            '<path fill="#000" d="M2 10h12v1H2zM2 5h1v5H2z"/>' +
            '</svg>') !important;
        }

        & > ul > li {
            border-color: #000 !important;
        }
	}
}