//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// A-Z Listing
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__a-z_listing {
	
    .qld__a-z_listing__header {

        @include QLD-media(lg) {
            max-width: 60%;
        }

        .qld__a-z_listing__header__intro {
            font-size: pxToRem(16); 
            line-height: pxToRem(24);

            @include QLD-media(md) {
                font-size: pxToRem(24);
                line-height: pxToRem(32);  
            }
        }
    }

    .qld__a-z_listing__options {
        @include sq-list-reset;
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        @include QLD-space(margin, 32px 0 64px);

        .qld__a-z_listing__options__item {
            @include QLD-space(margin, 0 12px 12px 0);

            a {
                display: inline-block;
                @include QLD-space(width, 32px);
                @include QLD-space(height, 32px);
                line-height: 1.82;
                text-align: center;
                color: var(--QLD-color-light__link);
                border: 1px solid var(--QLD-color-light__border--alt);
                @include QLD-space(padding, 1px);
                @include sq-border-radius(3px);
                text-transform: uppercase;
                text-decoration: none;

                &:not(:disabled) {
                    &:hover{
                        background-color: $QLD-color-neutral--lightest;
                        color: var(--QLD-color-light__text--lighter);
                        border: 2px solid var(--QLD-color-light__action--secondary-hover);
                        @include QLD-space(padding, 0);
                    }
                }

                &.active, &:active, &:focus {
                    color: var(--QLD-color-light__link--on-action);
                    background-color: var(--QLD-color-light__link);
                    border: none;
                    @include QLD-space(padding, 2px);
                    
                }
            }
        }
    }

    .qld__a-z_listing__list {
        @include sq-list-reset;
        display: flex;
        flex-direction: column;

        .qld__a-z_listing__list__item__header {
            font-size: pxToRem(32);
            @include QLD-space(margin, 24px 0 24px);
            text-transform: uppercase;
            // line-height: 1.5;

            @include QLD-media(md) {
                @include QLD-space(margin, 48px 0 24px);
            }

            &:first-of-type {
                @include QLD-space(margin-top, 0);
            }

            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $QLD-color-neutral--lighter;
                text-align: center;
                @include sq-border-radius(8px);
                width: pxToRem(64);
                height: pxToRem(64);
                background-color: $QLD-color-neutral--lightest;
            }

            &.active {
                span {
                    color: var(--QLD-color-dark__text);
                    background-color: var(--QLD-color-dark__background);
                }
            }
        }

        .qld__a-z_listing__list__item__services {
            border: 1px solid $QLD-color-neutral--lighter;
            border-radius: pxToRem(12);
            list-style: none;
            overflow-y: hidden;
            padding: 0;
            flex-direction: column;

            @include QLD-media(md) {
                @include QLD-space(margin-bottom, 4px);

                &:last-of-type {
                    @include QLD-space(margin-bottom, 0);
                }
            }


            .qld__a-z_listing__list__item__services__item {
                
                border-bottom: 1px solid $QLD-color-neutral--lighter;

                .qld__a-z_listing__list__item__services__item__link {
                    display: inline-block;
                    line-height: pxToRem(24);
                    @include QLD-space(padding, 12px);

                    &:hover {
                        svg {
                            color: var(--QLD-color-light__action--secondary-hover);
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                &:nth-child(2n) {
                    background-color: $QLD-color-neutral--lightest;
                }
            }
        }
    }
}

.qld__body .qld__a-z_listing ul {
    max-width: none;
}