//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// {{component_name}}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

.qld__banner.qld__banner_basic {

    .qld__banner__wrapper{

    }

    .qld__banner__wrapper{
        min-height: 152px;
    }

    //With breadcrumbs
    &.qld__banner--breadcrumbs{
        .qld__banner__content{
            @include QLD-space(padding-top, .5unit);
            @include QLD-space(padding-bottom, 2unit);
            @include QLD-media(md) {
                @include QLD-space(padding-top, 3unit);
                @include QLD-space(padding-bottom, 3unit);
    
                .qld__banner__breadcrumbs--desktop{
                    @include QLD-space(margin, 0 0 1.5rem);
                }
            }
            @include QLD-media(lg) {
                @include QLD-space(padding-bottom, 4unit);
            }
        }
    }

        

    //With hero image
    &.qld__banner--has-hero{
        .qld__banner__wrapper{
            @include QLD-media(md) {
                min-height: 260px;
            }
            @include QLD-media(lg) {
                min-height: 320px;
            }
        }
        .qld__banner__content{
            @include QLD-space(padding-top, 1unit);
        }
    }
        
    
}